import { BlockContainer } from '@components/Blocks';
import { useBlocksContext } from '@components/Blocks/useBlock';
import CtaButton from '@components/CtaButton';
import { createBlock } from '@lib/features/blocks/blocks';
import Txt from '@primitive/Txt';

const RichContent = createBlock<'blocks_richText_BlockType'>(
  ({ heading, content, linkCta, _blockMeta: { first } = {} }) => {
    const { disablePaddingStart } = useBlocksContext();

    return (
      <BlockContainer
        {...{
          maxWidth: 'md',
          disableTransition: true,

          ...(first &&
            disablePaddingStart && {
              marginTop: 'none',
            }),
        }}>
        {heading && (
          <Txt variant="h5" as="h2" cx={{ mB: 'gutter' }}>
            {heading}
          </Txt>
        )}
        <Txt html withLede={!!first}>
          {content}
        </Txt>
        {linkCta && <CtaButton variant="outlined" {...linkCta} cx={{ mT: 'lg' }} />}
      </BlockContainer>
    );
  }
);

export default RichContent;
